import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import shape from '../../assets/images/shape/shape1.svg'

const TestimonialsStyleTwo = () => {
    return (
        <div className="testimonials-area pt-100 pb-70 bg-fafafb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                    </span>
                    <h2></h2>
                    <p></p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-box">
                            <img src={client1} className="shadow-sm" alt="about" />
                            <p>Fantastic Service, highly recommend, quick and easy.</p>
                            <div className="client-info">
                                <h3>Andre Wilks</h3>
                                <span>Life Coach - Nottingham</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-box">
                            <img src={client2} className="shadow-sm" alt="about" />
                            <p>Very Good Service, understood the issue and guided me through what to do the next time.  Thank you Neville.</p>
                            <div className="client-info">
                                <h3>Zac Saif</h3>
                                <span>Project Manager</span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="shape-img1">
                <img src={shape} alt="about" />
            </div>
        </div>
    )
}

export default TestimonialsStyleTwo
import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'


const TestimonialsStyleOne = () => {
    return (
        <div className="testimonials-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients are Saying?</h2>
                    <p></p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>Thanks for the excellent business support, we are really happy with the service and knowing that our Macs are looked after means we can get on and do what we are good at.</p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client1} alt="about" />
                                    <div className="title">
                                        <h3>Alex Maxwell</h3>
                                        <span>CEO at Playjam</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>We are a service provider to the education market and Spurdotech fill in the gaps with the Mac Support, always punctual and professional.</p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client2} alt="about" />
                                    <div className="title">
                                        <h3>David Warner</h3>
                                        <span>CEO at Datalect</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    )
}

export default TestimonialsStyleOne